

import request from '@/utils/request';


class ReconcileApi {

    list = (data) => {
        return request({
            url: '/adm/accounting/reconcile/list',
            method: 'post',
            data
        });
    }

    reconcile_stmt_list = (data) => {
        return request({
            url: '/adm/accounting/reconcile/bank_reconcile_stmt_list',
            method: 'post',
            data
        });
    }

    stmt_records_list = (data) => {
        return request({
            url: '/adm/accounting/reconcile/bank_reconcile_stmt_review_query',
            method: 'post',
            data
        });
    }

    update_account_stmt = (data) => {
        return request({
            url: '/adm/accounting/reconcile/update_stmt',
            method: 'post',
            data
        });

    }

    reconcil = (data) => {
        return request({
            url: '/adm/accounting/reconcile/reconcile',
            method: 'post',
            data
        });
    }

    reconcile_store = (data) => {
        return request({
            url: '/adm/accounting/reconcile/reconcile_store',
            method: 'post',
            data
        });
    }

    add_reconcil_miscellaneous_record = (data) => {
        return request({
            url: '/adm/accounting/reconcile/add_new_miscellaneous_stmt',
            method: 'post',
            data
        });
    }

    delete_stmt_record = (data) => {
        return request({
            url: '/adm/accounting/reconcile/delete_miscellaneous_stmt',
            method: 'post',
            data
        });
    }

    update_miscellaneous_record = (data) => {
        return request({
            url: '/adm/accounting/reconcile/update_miscellaneous_stmt',
            method: 'post',
            data
        });
    }


    get_current_reconciled_date = (data) => {
        return request({
            url: '/adm/accounting/reconcile/get_current_reconciled_date',
            method: 'post',
            data
        });	
    }

    finallized_reconciled = (data) => {
        return request({
            url: '/adm/accounting/reconcile/reconciled_finalized',
            method: 'post',
            data
        });	
    }

    preview_reconciled_statement = (data) => {
        return request({
            url: '/adm/accounting/reconcile/preview_reconciled_statement',
            method: 'post',
            data
        });	
    }

    get_reconciled_reports_file = (data) => {
        return request({
            url: '/adm/accounting/reconcile/get_reconciled_statement_report_zip_file',
            method: 'post',
            data
        });	
        
    }

    export_reconciled_records = (data) => {
        return request({
            url: '/adm/accounting/reconcile/export_reconciled_list',
            method: 'post',
            data
        });	
    }

    unfinalized_reconcile = (data) => {
        return request({
            url: '/adm/accounting/reconcile/reverse_finalized_reconcile',
            method: 'post',
            data
        });	
    }

}

let _reconcile_api = null

const getBankReconciledApi = () => {
    if (!_reconcile_api) {
        _reconcile_api = new ReconcileApi();
    }
    return _reconcile_api;
}

export {getBankReconciledApi};