<script>


/**
 * Deposit Edit component
 */

 import {
  required,
} from "vuelidate/lib/validators";


export default {

    emits: ['confirm', 'cancel'],

    props: ['bank_account'],
    components: {
        //flatPickr
    },

    validations: {
        amount : {required}
    },

    

    data() {
        return {
            submitted : false,
            amount : 0
        }
    },

    methods: {
        formSubmit() {
        
            this.submitted = true
            this.$v.$touch();
            if (this.$v.$error == false) { 
                
                this.$emit('confirm', this.amount)
            }
        },

    },

    created() {
      this.amount = this.bank_account.account_stmt
    },

    mounted() {

    }

};
</script>

<template>
    <div class="card"> 
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom01">Statment </label>
                            <input id="validationCustom01" v-model="amount" type="text"
                                class="form-control" placeholder="$"  :class="{
                                    'is-invalid': submitted && $v.amount.$error,
                                }" />
                            <div v-if="submitted && $v.amount.$error" class="invalid-feedback">
                                <span v-if="!$v.amount.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                </div>
                 <!-- end row-->

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>