<script>


/**
 * Deposit Edit component
 */

 import {
  required,
} from "vuelidate/lib/validators";

import flatPickr from "vue-flatpickr-component";
 import "flatpickr/dist/flatpickr.css";


export default {

    emits: ['confirm', 'cancel'],

    props: ['data'],

    components: {
        flatPickr
    },

    validations: {
        amount : {required},
        date : {required}
    },

    

    data() {
        return {
            description : '',
            amount : 0,
            date : ''
        }
    },

    methods: {
        formSubmit() {
            
            this.$v.$touch();
            if (this.$v.$error == false) { 
                let data = {
                    amount :this.amount,
                    desc : this.description,
                    date : this.date,
                }
                if (this.data && this.data.id) {
                    data.id = this.data.id
                }
                this.$emit('confirm',data)
            }
        },

    },

    created() {
      
    },

    mounted() {
        if (this.data) {
            this.amount       = this.data.amount
            this.description  =  this.data.remarks
            this.date         =  this.data.update_date
        }
    }

};
</script>

<template>
    <div class="card"> 
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom01">Amount </label>
                            <input id="validationCustom01" v-model="amount" type="text"
                                class="form-control" placeholder="$"  :class="{
                                    'is-invalid':  $v.amount.$error,
                                }" />
                            <div v-if=" $v.amount.$error" class="invalid-feedback">
                                <span v-if="!$v.amount.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom01">Date </label>
                            <flat-pickr
                                v-model="date"
                                placeholder="Select a date"
                                class="form-control"
                                :class="{
                                    'is-invalid': $v.date.$error,
                                }"
                                ></flat-pickr>
                            <div v-if=" $v.date.$error" class="invalid-feedback">
                                <span v-if="!$v.date.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    
                </div>
                 <!-- end row-->

                 <div class="row">
                    <div class="col-md-12">
                        <div class="mb-3">
                            <label for="validationCustom01">Description </label>
                            <input id="validationCustom01" v-model="description" type="text"
                                class="form-control" placeholder=""  />

                        </div>
                    </div>
                    
                </div>
                 <!-- end row-->

                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button  variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>
                    
                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>